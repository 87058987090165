const headerPlayerDetail = [
    { text: 'សកម្មភាព', align: 'start', value: 'actions', sortable: false },
    {
      text: 'No',
      align: 'start',
      value: 'no',
    },
    { text: 'App', value: '_id.app_id' },
    { text: 'Player', value: '_id.player' },
    { text: 'Balance', value: '_id.player_balance' },
    { text: 'Amount Turnover', value: 'amount' },
    { text: 'AmountWin', value: 'amount_win' },
    { text: 'AmountLose', value: 'amount_lose' }]

export default headerPlayerDetail;

